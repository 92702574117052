import { css } from "styled-components";
import RiotLocale from "../../enums/riot-locale";
import riotLocaleToBcp47 from "../../utils/riot-locale-to-bcp47";

/**
 * Some locales don't support italic fonts, so only set it on those
 * that support it.
 */
const cssLocalizedItalic = css`
  &:not(:lang(${riotLocaleToBcp47(RiotLocale.ko_KR)})) {
    font-style: italic;
  }
`;

export default cssLocalizedItalic;
