// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-contentstack-content-types-articles-templates-default-index-tsx": () => import("./../../../src/contentstack/content-types/articles/templates/default/index.tsx" /* webpackChunkName: "component---src-contentstack-content-types-articles-templates-default-index-tsx" */),
  "component---src-contentstack-content-types-articles-templates-patch-notes-index-tsx": () => import("./../../../src/contentstack/content-types/articles/templates/patch-notes/index.tsx" /* webpackChunkName: "component---src-contentstack-content-types-articles-templates-patch-notes-index-tsx" */),
  "component---src-contentstack-content-types-categories-templates-default-index-tsx": () => import("./../../../src/contentstack/content-types/categories/templates/default/index.tsx" /* webpackChunkName: "component---src-contentstack-content-types-categories-templates-default-index-tsx" */),
  "component---src-contentstack-content-types-champions-templates-default-index-tsx": () => import("./../../../src/contentstack/content-types/champions/templates/default/index.tsx" /* webpackChunkName: "component---src-contentstack-content-types-champions-templates-default-index-tsx" */),
  "component---src-contentstack-content-types-landing-pages-templates-default-index-tsx": () => import("./../../../src/contentstack/content-types/landing-pages/templates/default/index.tsx" /* webpackChunkName: "component---src-contentstack-content-types-landing-pages-templates-default-index-tsx" */),
  "component---src-contentstack-content-types-pages-templates-default-index-tsx": () => import("./../../../src/contentstack/content-types/pages/templates/default/index.tsx" /* webpackChunkName: "component---src-contentstack-content-types-pages-templates-default-index-tsx" */),
  "component---src-contentstack-content-types-tags-templates-default-index-tsx": () => import("./../../../src/contentstack/content-types/tags/templates/default/index.tsx" /* webpackChunkName: "component---src-contentstack-content-types-tags-templates-default-index-tsx" */),
  "component---src-gatsby-blank-tsx": () => import("./../../../src/gatsby/blank.tsx" /* webpackChunkName: "component---src-gatsby-blank-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */)
}

