import { css } from "styled-components";
import RiotLocale from "../../enums/riot-locale";
import riotLocaleToBcp47 from "../../utils/riot-locale-to-bcp47";

/**
 * Some locales that mix different languages (e.g. Turkish uses English champion names)
 * have issues with auto-capitalization. CSS doesn't know that some text is not
 * in the local language, so it still formats it as if it was localized (e.g. "Akali"
 * becomes "AKALİ" instead of "AKALI").
 *
 * To avoid this, we don't enable automatic uppercasing of copy for those locales.
 */
const cssLocalizedUppercase = css`
  html
    :not(:lang(${riotLocaleToBcp47(RiotLocale.el_GR)}))
    :not(:lang(${riotLocaleToBcp47(RiotLocale.ja_JP)}))
    :not(:lang(${riotLocaleToBcp47(RiotLocale.ko_KR)}))
    :not(:lang(${riotLocaleToBcp47(RiotLocale.th_TH)}))
    :not(:lang(${riotLocaleToBcp47(RiotLocale.tr_TR)}))
    :not(:lang(${riotLocaleToBcp47(RiotLocale.zh_TW)}))
    & {
    text-transform: uppercase;
  }
`;

export default cssLocalizedUppercase;
