import { useState, useEffect } from "react";
import { Config } from "webfontloader";

interface State {
  loading: boolean;
  active: boolean;
  inactive: boolean;
}

/**
 * Use @see https://github.com/typekit/webfontloader to listen for font loading events and state changes
 * @param config Standard Web Font Loader config, @see https://github.com/typekit/webfontloader#configuration
 * @param deps
 * @returns Returns the overall loading state
 */
export function useWebFontLoader(
  { loading, active, inactive, fontloading, fontactive, fontinactive, ...otherConfigProps }: Config,
  deps: any[] = [],
): State {
  const [state, setState] = useState<State>({
    loading: false,
    active: false,
    inactive: false,
  });

  useEffect(() => {
    if (typeof window === "undefined") return;
    let inEffect = true;

    import(
      /* webpackMode: "eager" */
      "webfontloader"
    ).then((WebFont) => {
      if (!inEffect) return;

      WebFont.load({
        ...otherConfigProps,
        loading: () => {
          if (!inEffect) return;
          setState({ ...state, loading: true });
          if (loading) loading();
        },
        active: () => {
          if (!inEffect) return;
          setState({ ...state, active: true });
          if (active) active();
        },
        inactive: () => {
          if (!inEffect) return;
          setState({ ...state, inactive: true });
          if (inactive) inactive();
        },
        fontloading: (...args) => {
          if (!inEffect) return;
          if (fontloading) fontloading(...args);
        },
        fontactive: (...args) => {
          if (!inEffect) return;
          if (fontactive) fontactive(...args);
        },
        fontinactive: (...args) => {
          if (!inEffect) return;
          if (fontinactive) fontinactive(...args);
        },
      });
    });

    return () => {
      inEffect = false;
    };
  }, [...deps]);

  return state;
}
