import { useState, useEffect } from "react";

/**
 * Returns whether element can play media or not
 *
 * @param ref
 * @param deps
 * @returns State of whether ref can play media
 */
export function useMediaCanPlay(ref: React.RefObject<HTMLVideoElement | HTMLAudioElement>, deps: any[] = []): boolean {
  const [canPlay, setCanPlay] = useState<boolean>(false);

  useEffect(() => {
    const current = ref.current;

    if (!current) return setCanPlay(false);
    if (current.readyState > 0) return setCanPlay(true);

    const canPlayFn = () => setCanPlay(true);

    current.addEventListener("canplay", canPlayFn);
    return () => current.removeEventListener("canplay", canPlayFn);
  }, [ref.current]);

  return canPlay;
}
