import { css } from "styled-components";
import RiotLocale from "../../enums/riot-locale";
import riotLocaleToBcp47 from "../../utils/riot-locale-to-bcp47";
import { FONT_FAMILY_SERIF, FONT_FAMILY_SANS_SERIF } from "../../layouts/default/theme";

/**
 * Some locales require specific serif fonts
 */
export const cssLocalizedFontFamilySerif = css`
  font-family: ${FONT_FAMILY_SERIF};

  &:lang(${riotLocaleToBcp47(RiotLocale.ko_KR)}) {
    font-family: "NotoSans-Medium", serif;
  }

  &:lang(${riotLocaleToBcp47(RiotLocale.th_TH)}) {
    font-family: "NeueFrutigerThaiModern", serif;
  }
`;

/**
 * Some locales require specific sans serif fonts
 */
export const cssLocalizedFontFamilySansSerif = css`
  font-family: ${FONT_FAMILY_SANS_SERIF};

  &:lang(${riotLocaleToBcp47(RiotLocale.ko_KR)}) {
    font-family: "NotoSans-Medium", sans-serif;
  }

  &:lang(${riotLocaleToBcp47(RiotLocale.th_TH)}) {
    font-family: "NeueFrutigerThaiModern", sans-serif;
  }
`;
