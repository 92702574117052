import { useState, useContext } from "react";
import { useTicker, useResize } from ".";
import { calculateOffsetTop } from "../dom";
import { ManagerContext } from "../../managers";
import { lerpClamped } from "../math";

type Handler = (
  data: {
    delta: number;
    elapsed: number;
    stamp: number;
  },
  extended: {
    in: number;
    out: number;
    direct: {
      in: number;
      out: number;
    };
  },
) => void;

/**
 * Custom hook which extends the default useTicker hook to provide scroll activation data.
 * Useful for animating content smoothly as scroll position changes.
 *
 * @param element The target element whose position we are tracking with respect to viewport
 * @param handler Called on every tick with element activation info
 * @param dependencies
 * @param easingFactor
 */
export function useTickerWithScrollActivation(
  element: HTMLElement | null,
  handler: Handler,
  dependencies: any[] = [],
  easingFactor = 0.15,
) {
  const { viewport } = useContext(ManagerContext);
  const [isInit, setIsInit] = useState(false);
  const [offsetTop, setOffsetTop] = useState(999999); // default to prevent false initial positive
  const [height, setHeight] = useState(1); // default of 1 to prevent division by zero

  // Not updated using setter, updated out of react state for performance reasons
  const [activation] = useState({ in: 0, out: 0, direct: { in: 0, out: 0 } });

  useResize(() => {
    if (!element) return;

    const viewportHeight = viewport.latest.height;

    setOffsetTop(calculateOffsetTop(element, viewport.latest.scrollTop));
    setHeight(
      // Ensure a minimum of 1 to prevent division by zero
      Math.max(1, viewportHeight, element.getBoundingClientRect().height),
    );
  }, [element, isInit]);

  useTicker(
    ({ ...args }) => {
      if (!isInit) setIsInit(true);

      activation.direct.in = Math.min(
        Math.max(viewport.latest.scrollTop + viewport.latest.height - offsetTop, 0) / viewport.latest.height,
        1,
      );
      activation.direct.out = Math.min(
        Math.min(1 - Math.max(viewport.latest.scrollTop + height - offsetTop, 0) / height, 0) * -1,
        1,
      );

      activation.in = lerpClamped(activation.in, activation.direct.in, easingFactor, 0.0005);
      activation.out = lerpClamped(activation.out, activation.direct.out, easingFactor, 0.0005);

      handler({ ...args }, { ...activation });
    },
    [element, offsetTop, ...dependencies],
  );
}
