import React from "react";
import documentScrollingElement from "../utils/document-scrolling-element";

/**
 * This idea is based on how Facebook deals with overlays when the root scrollable element
 * is <body> (or <html>). Basically it makes the content wrapper (in this case <div id="___gatsby">)
 * a "position: fixed" element, and positions the top of it to correspond to the scrollTop position. This
 * prevents any scrolling of the main content. When unfreezing, the scrollTop and positioning is
 * reset to previous state.
 */
const FreezeScrollingElementContext = React.createContext<(freeze: boolean) => void>((freeze) => {
  const el = document.getElementById("___gatsby");
  if (!el) return;
  if (!documentScrollingElement) return;

  if (freeze) {
    el.style.setProperty("top", -1 * documentScrollingElement.scrollTop + "px");
    el.style.setProperty("width", "100%");
    el.style.setProperty("position", "fixed");
  } else {
    const scrollTop = Math.abs(parseInt(el.style.getPropertyValue("top"), 10)) || 0;
    el.style.removeProperty("top");
    el.style.removeProperty("width");
    el.style.removeProperty("position");
    documentScrollingElement.scrollTop = scrollTop;
  }
});

export default FreezeScrollingElementContext;
