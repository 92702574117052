/*
    Modulo function that handles wrapping of negative numbers
    ---
    val        Number      Number to perform operation on
    mod        Number      Modulo value
    ---
    Returns    Number      Modulus value
*/

export default function (val: number, mod: number) {
  return ((val % mod) + mod) % mod;
}
