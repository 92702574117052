import React, { useState, useEffect, useContext } from "react";
import { useResize, useWebFontLoader } from "../../utils/hooks";
import Helmet from "react-helmet";
import RiotBar from "@riotgames/riotbar-react";
import RiotLocaleContext from "../../contexts/riot-locale";

import favIconUrl from "../../assets/favicon.ico";

import { GlobalStyle, Header, Main } from "./style";

import { activateManagers, deactivateManagers, ViewportManagerInstance, InputManagerInstance } from "../../managers";

import ScrollElementContext from "../../contexts/scroll-element";
import FreezeScrollingElementContext from "../../contexts/freeze-scrolling-element";
import StickyOffsetContext from "../../contexts/sticky-offset";

import LoLTheme from "@riotgames/wwpub-components/lib/themes/LoL";
import { cssLocalizedFontFamilySansSerif, cssLocalizedFontFamilySerif } from "../../components/css";

const Layout: React.FC = (props) => {
  const [scrollElement, setScrollElement] = useState<HTMLElement | null>(useContext(ScrollElementContext));
  const freezeScrollingElement = useContext(FreezeScrollingElementContext);

  useWebFontLoader({
    custom: {
      families: [`${cssLocalizedFontFamilySerif}`, `${cssLocalizedFontFamilySansSerif}`],
    },
    fontactive: (familyName: string, fvd: string) => {
      // console.log(`font active (familyName: ${familyName}, fvd: ${fvd})`)
      ViewportManagerInstance.refresh();
      InputManagerInstance.refresh();
    },
    // Once all webfonts loaded and rendered...
    active: () => {
      ViewportManagerInstance.apply();
    },
  });

  // Bind global managers...
  useEffect(() => {
    if (scrollElement === null) return;
    activateManagers(scrollElement);
    const delayedInitialResize = setTimeout(() => {
      ViewportManagerInstance.refresh();
      ViewportManagerInstance.apply();
      InputManagerInstance.refresh();
    }, 3000); // 3s provides enough time for initial entry animations to finish first
    return () => {
      clearTimeout(delayedInitialResize);
      deactivateManagers();
    };
  }, [scrollElement]); // Only refresh managers when the scrollElement changes

  // ...also keep the InputManager bounds up-to-date
  useResize(() => {
    if (scrollElement === null) return;
    InputManagerInstance.refresh();
  }, [scrollElement]);

  const locale = useContext(RiotLocaleContext);
  const riotbarEnvironment = (process && process.env && process.env.GATSBY_RIOTBAR_ENVIRONMENT) || "live";

  // push customEvent to GTM dataLayer if user logged in
  const [hasPushedLogin, setHasPushedLogin] = useState(false); // @TODO Maybe this should be changed to use session storage so it doesn't get invoked every page load?
  const handleAuthUpdate = () => {
    if (!hasPushedLogin) {
      try {
        const authState = (window as any).RiotBar.account.getAuthState();
        if (authState) {
          setHasPushedLogin(true);
          // Pushing puuid and summoner to the GTM dataLayer
          window.dataLayer.push({
            event: "Login",
            summoner: authState.name || "",
            puuid: authState.sub || "",
          });
        }
      } catch (err) {
        console.warn && console.warn(err);
      }
    }
  };

  return (
    <React.Fragment>
      <Helmet>
        {/* disable automatic detection of phone numbers in html on iOS safari (and other browsers) */}
        <meta name="format-detection" content="telephone=no" />

        {/* favicon */}
        <link rel="icon" href={favIconUrl} />

        {/* load fonts file, TODO: look into just loading fonts we use and adding it to our own css file */}
        <link type="text/css" rel="stylesheet" href="https://s.lolstatic.com/awesomefonts/1.0.0/lol-fonts.css" />

        <link
          type="text/css"
          rel="stylesheet"
          href="https://lolstatic-a.akamaihd.net/webfonts/live/css/fonts/neuefruitegermodern.css"
        />

        {/* preload regular font */}
        <link
          rel="preload"
          href="https://s.lolstatic.com/awesomefonts/1.0.0/Fonts/BeaufortforLOL-Regular.woff"
          as="font"
          type="font/woff"
          crossOrigin="anonymous"
        />

        {/* preload bold font */}
        <link
          rel="preload"
          href="https://s.lolstatic.com/awesomefonts/1.0.0/Fonts/BeaufortforLOL-Bold.woff"
          as="font"
          type="font/woff"
          crossOrigin="anonymous"
        />
      </Helmet>

      <ScrollElementContext.Provider value={scrollElement}>
        <FreezeScrollingElementContext.Provider value={freezeScrollingElement}>
          {/* meta */}
          <GlobalStyle />

          <LoLTheme>
            {/* header */}
            <Header id="riotbar-header">
              <RiotBar
                locale={locale || "en-us"}
                product="lol"
                environment={riotbarEnvironment}
                onAuthUpdate={handleAuthUpdate}
              />
            </Header>

            {/* body */}
            <StickyOffsetContext.Provider value={0}>
              <Main>
                {props.children}

                {/* footer */}
                <footer id="riotbar-footer" />
              </Main>
            </StickyOffsetContext.Provider>
          </LoLTheme>
        </FreezeScrollingElementContext.Provider>
      </ScrollElementContext.Provider>
    </React.Fragment>
  );
};

export default Layout;
