import { useInView as useInViewInterObs, IntersectionOptions, InViewHookResponse } from "react-intersection-observer";

/**
 * Return updated inView state as intersection state changes
 *
 * @param options
 * @param option.root
 * @param option.rootMargin
 * @param option.threshold
 * @param options.triggerOnce Only trigger the inView callback once
 * @retuns Returns inView state
 */
export function useInView(options?: IntersectionOptions): InViewHookResponse {
  // copy options object
  options = options ? { ...options } : {};

  return useInViewInterObs(options);
}
