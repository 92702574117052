import { useEffect } from "react";

/**
 * A custom hook which wraps window.matchMedia registration
 *
 * @param mediaQuery Media query used to determine whether to call handler
 * @param handler Called when media query match state changes
 * @param deps Triggers handler when dependencies change
 */
export function useMedia(mediaQuery: string, handler: (match: boolean) => void, deps: any[] = []) {
  useEffect(() => {
    if (typeof window === "undefined") return;

    const mediaQueryList = window.matchMedia(mediaQuery);

    function propagate(e: MediaQueryListEvent) {
      if (e.matches) {
        handler(true);
      } else {
        handler(false);
      }
    }

    // Subscribe
    mediaQueryList.addListener(propagate);
    // Fire an initial invocation
    handler(mediaQueryList.matches);
    // Cleanup
    return () => {
      mediaQueryList.removeListener(propagate);
    };
  }, [mediaQuery, ...deps]);
}
