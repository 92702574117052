import RiotLocale from "../enums/riot-locale";

/**
 * Converts from Riot Locale format to the more standard BCP 47 format.
 *
 * @param riotLocale Riot locale is of the form <ISO 639-1 language code>-<ISO 3166-1 region code>.
 * @returns Locale of "<ISO 639-1 language code>_<ISO 3166-1 region code>" format.
 */
export default function riotLocaleToBcp47(riotLocale: RiotLocale): string {
  return riotLocale.replace("_", "-").toLowerCase();
}
