import { useEffect, useState, useContext } from "react";
import { ManagerContext } from "../../managers";
import { calculateOffsetTop } from "../../utils/dom";
import Tween from "../classes/Tween";
import { inOutSine, EasingFn } from "../math/easing";

export interface ScrollOptions {
  ref: React.RefObject<HTMLElement>;
  duration?: number;
  ease?: EasingFn;
  offset?: number;
  threshold?: number;
  preventCallback?: () => boolean;
  doneCallback?: () => void;
}

/**
 * Scrolls to ref element when deps change.
 *
 * @param options
 * @param options.ref Reference to element to scroll to.
 * @param options.duration How long the scrolling should take
 * @param options.offset Offset amount of pixels to scroll by with respect to ref position
 * @param options.threshold Scroll to element only if it's more than threshold down the page, with respect to page height.
 * @param options.preventCallback Called before proceeding with normal execution. Allows preventing scroll based on state.
 * @param options.doneCallback Called once scrolling is complete
 * @param deps Array of values which when change, will trigger the scroll
 */
export function useScrollTo(
  {
    ref,
    duration = 300,
    ease = inOutSine,
    offset = 0,
    threshold = 0,
    preventCallback = () => false,
    doneCallback,
  }: ScrollOptions,
  deps: any[],
) {
  const viewport = useContext(ManagerContext).viewport;
  const [initialized, setInitialized] = useState<boolean>(false);
  const [isMs] = useState(() => {
    return typeof window !== "undefined" && document.documentElement.classList.contains("is-ms");
  });

  useEffect(() => {
    // don't run on initial render
    if (!initialized) return setInitialized(true);

    if (preventCallback()) return;

    const { scrollElement, scrollTop, height } = viewport.latest;

    // ensure there is an element to scroll
    if (!scrollElement) return;

    // ensure there is an element to scroll to
    const element = ref.current;
    if (!element) return;

    const offsetTop = calculateOffsetTop(element, viewport.latest.scrollTop);

    const distance = offsetTop - scrollTop + offset;

    if (offsetTop >= scrollTop && offsetTop < scrollTop + height && distance / height < threshold) {
      return doneCallback && doneCallback();
    }

    const tween = new Tween(
      duration,
      ease,
      (value, progress) => {
        const scrollPos = scrollTop + Math.round(distance * value);
        isMs ? (scrollElement.scrollTop = scrollPos) : scrollElement.scrollTo(0, scrollPos);
      },
      () => doneCallback && doneCallback(),
    );

    tween.start();

    return () => {
      tween.kill();
    };
  }, [...deps]);
}
