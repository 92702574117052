import { css } from "styled-components";
import RiotLocale from "../../enums/riot-locale";
import riotLocaleToBcp47 from "../../utils/riot-locale-to-bcp47";

/**
 * While for some locales we can make the font very small, for others it becomes
 * illegible. To avoid this, use locale-specific font-size rules for tiny font sizes.
 */
const cssLocalizedMinFontSize = css`
  font-size: 10px;

  &:lang(${riotLocaleToBcp47(RiotLocale.ja_JP)}),
  &:lang(${riotLocaleToBcp47(RiotLocale.ko_KR)}),
  &:lang(${riotLocaleToBcp47(RiotLocale.th_TH)}),
  &:lang(${riotLocaleToBcp47(RiotLocale.zh_TW)}) {
    font-size: 12px;
  }
`;

export default cssLocalizedMinFontSize;
