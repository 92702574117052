import { useEffect, useContext } from "react";
import { ManagerContext, ViewportEventType } from "../../managers";
import { ScrollHandler } from "../../managers/viewport-manager";

/**
 * A custom hook which wraps ViewportManager scroll registration.
 * This improves ergonomics and ensures cleanup is always handled appropriately.
 *
 * @param handler Called when viewport scrolled
 * @param dependencies Calls handler when array values change
 */
export function useScroll(handler: ScrollHandler, dependencies: any[] = []) {
  const { viewport } = useContext(ManagerContext);
  const { scroll, scrollTop, scrollHeight } = viewport.latest;

  useEffect(() => {
    // Subscribe
    const scrollId = viewport.on(ViewportEventType.SCROLL, handler);
    // Fire an initial invocation
    handler({ scroll, scrollTop, scrollHeight });
    // Cleanup
    return () => {
      viewport.off(scrollId);
    };
  }, [...dependencies]);
}
