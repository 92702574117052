import { css } from "styled-components";
import RiotLocale from "../../enums/riot-locale";
import riotLocaleToBcp47 from "../../utils/riot-locale-to-bcp47";

/**
 * Some locales have accents on capitalized letters, so it's important that they have
 * more breathing room compared to other locales. We ensure that lines of text don't overlap
 * by limiting minimum line-height for these locales.
 */
const cssLocalizedLineHeight = (targetLineHeight: number) => css`
  line-height: ${targetLineHeight};

  &:lang(${riotLocaleToBcp47(RiotLocale.cs_CZ)}),
  &:lang(${riotLocaleToBcp47(RiotLocale.de_DE)}),
  &:lang(${riotLocaleToBcp47(RiotLocale.es_AR)}),
  &:lang(${riotLocaleToBcp47(RiotLocale.es_ES)}),
  &:lang(${riotLocaleToBcp47(RiotLocale.es_MX)}),
  &:lang(${riotLocaleToBcp47(RiotLocale.fr_FR)}),
  &:lang(${riotLocaleToBcp47(RiotLocale.hu_HU)}),
  &:lang(${riotLocaleToBcp47(RiotLocale.it_IT)}),
  &:lang(${riotLocaleToBcp47(RiotLocale.ja_JP)}),
  &:lang(${riotLocaleToBcp47(RiotLocale.ko_KR)}),
  &:lang(${riotLocaleToBcp47(RiotLocale.pl_PL)}),
  &:lang(${riotLocaleToBcp47(RiotLocale.pt_BR)}),
  &:lang(${riotLocaleToBcp47(RiotLocale.ro_RO)}),
  &:lang(${riotLocaleToBcp47(RiotLocale.ru_RU)}),
  &:lang(${riotLocaleToBcp47(RiotLocale.th_TH)}),
  &:lang(${riotLocaleToBcp47(RiotLocale.tr_TR)}),
  &:lang(${riotLocaleToBcp47(RiotLocale.vi_VN)}),
  &:lang(${riotLocaleToBcp47(RiotLocale.zh_CN)}),
  &:lang(${riotLocaleToBcp47(RiotLocale.zh_TW)}) {
    line-height: ${targetLineHeight < 1.1 ? 1.1 : targetLineHeight};
  }
`;

export default cssLocalizedLineHeight;
