module.exports = [{
      plugin: require('../plugins/gatsby-plugin-incorrect-locale/gatsby-browser.js'),
      options: {"plugins":[],"localeArray":["cs-cz","de-de","el-gr","en-au","en-gb","en-pl","en-us","es-es","es-mx","fr-fr","hu-hu","it-it","ja-jp","ko-kr","pl-pl","pt-br","ro-ro","ru-ru","tr-tr","en-sg","en-ph","zh-tw","vi-vn","th-th"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/mnt/nfs/wwpub/static_sites/leagueoflegends-web/leagueoflegends-web-static-blue/persistent/locale","languages":["cs-cz","de-de","el-gr","en-au","en-gb","en-pl","en-us","es-es","es-mx","fr-fr","hu-hu","it-it","ja-jp","ko-kr","pl-pl","pt-br","ro-ro","ru-ru","tr-tr","en-sg","en-ph","zh-tw","vi-vn","th-th"],"defaultLanguage":"en-us","redirect":true},
    },{
      plugin: require('../node_modules/@riotgames/gatsby-plugin-geotargeting/gatsby-browser.ts'),
      options: {"plugins":[],"supportedLocales":["cs-cz","de-de","el-gr","en-au","en-gb","en-pl","en-us","es-es","es-mx","fr-fr","hu-hu","it-it","ja-jp","ko-kr","pl-pl","pt-br","ro-ro","ru-ru","tr-tr","en-sg","en-ph","zh-tw","vi-vn","th-th"],"defaultLanguage":"en-gb"},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://eb443c710a0043fd9ffedaa8e7fd357f@sentry.io/1809126","environment":"live","sampleRate":0.001,"attachStacktrace":true,"tracesSampleRate":0.1,"release":"605b8b4f0926831aa1648f5553f8e5b7957cbbfa"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-N98J","includeInDevelopment":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-ts-config/gatsby-browser.js'),
      options: {"plugins":[],"configDir":"src/gatsby"},
    }]
