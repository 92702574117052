import { createGlobalStyle, css, default as styled } from "styled-components";
import { normalize } from "styled-normalize";
import RiotLocale from "../../enums/riot-locale";
import riotLocaleToBcp47 from "../../utils/riot-locale-to-bcp47";
import { cssLocalizedFontFamilySansSerif } from "../../components/css";
import { carouselStyle } from "../../components/carousel/style";

const baseStyle = css`
  body {
    cursor: default;
    -webkit-tap-highlight-color: transparent;

    ${cssLocalizedFontFamilySansSerif}
  }

  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin-bottom: 0;
  }

  :focus {
    outline: 0;
  }

  a[href],
  input[type="submit"],
  input[type="image"],
  label[for],
  select,
  button {
    cursor: pointer;

    &:disabled {
      cursor: auto;
    }
  }

  // hide X that shows up in IE inside <input> fields
  ::-ms-clear {
    display: none;
  }
`;

const layoutStyle = css`
  body {
    overflow-y: scroll;
  }
`;
const localizedStyle = css`
  :lang(${riotLocaleToBcp47(RiotLocale.ko_KR)}) {
    word-break: keep-all;
    letter-spacing: normal !important;
  }
  :lang(${riotLocaleToBcp47(RiotLocale.ja_JP)}) {
    letter-spacing: normal !important;
  }
`;

export const GlobalStyle = createGlobalStyle`
  ${normalize}
  ${baseStyle}
  ${layoutStyle}
  ${localizedStyle}
  ${carouselStyle}
`;

export const Header = styled.header`
  z-index: 1000;
  top: 0px;
`;

export const Main = styled.div``;
