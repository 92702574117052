import { useEffect } from "react";
import { useResize } from ".";
import "objectFitPolyfill";

export interface ObjectPolyfillWindow extends Window {
  objectFitPolyfill?: (el: HTMLElement) => void;
}
declare const window: ObjectPolyfillWindow;

export type ObjectFitType = "none" | "contain" | "cover" | "fill" | "scale-down";

export interface Options {
  objectFit?: ObjectFitType;
  objectPosition?: string;
}

export type Response = [
  {
    objectFit?: ObjectFitType;
    objectPosition?: string;
  },
];

/**
 * A custom hook which polyfills object-fit/object-position functionality when not natively supported by the browser
 * @param ref
 * @param options
 * @param deps
 */
export function useObjectFit(
  ref: React.RefObject<HTMLElement>,
  { objectFit, objectPosition }: Options = {},
  deps: any[] = [ref.current],
): Response {
  const updatePosition = () => {
    const current = ref.current;

    if (!current) return;
    if (typeof window === "undefined" || !window.objectFitPolyfill) return;

    if (current.getAttribute("data-object-fit")) {
      // insert polyfill styles
      window.objectFitPolyfill(current);
    } else {
      // remove polyfill styles
      ["left", "top", "width", "height", "margin-top", "margin-left", "position"].forEach((prop) =>
        current.style.removeProperty(prop),
      );
    }
  };

  // update when ref object changes
  useEffect(() => {
    if (!ref.current) return;

    if (objectFit) {
      ref.current.setAttribute("data-object-fit", objectFit);
    } else {
      ref.current.removeAttribute("data-object-fit");
    }

    if (objectPosition) {
      ref.current.setAttribute("data-object-position", objectPosition);
    } else {
      ref.current.removeAttribute("data-object-position");
    }

    updatePosition();
  }, [objectFit, objectPosition, ...deps]);

  // update when window resized
  useResize(() => updatePosition());

  return [
    {
      objectFit,
      objectPosition,
    },
  ];
}
