enum RiotLocale {
  cs_CZ = "cs-cz",
  de_DE = "de-de",
  el_GR = "el-gr",
  en_AU = "en-au",
  en_GB = "en-gb",
  en_PH = "en-ph",
  en_PL = "en-pl",
  en_SG = "en-sg",
  en_US = "en-us",
  es_AR = "es-ar",
  es_ES = "es-es",
  es_MX = "es-mx",
  fr_FR = "fr-fr",
  hu_HU = "hu-hu",
  id_ID = "id-id",
  it_IT = "it-it",
  ja_JP = "ja-jp",
  ko_KR = "ko-kr",
  ms_MY = "ms-my",
  pl_PL = "pl-pl",
  pt_BR = "pt-br",
  ro_RO = "ro-ro",
  ru_RU = "ru-ru",
  th_TH = "th-th",
  tr_TR = "tr-tr",
  vi_VN = "vi-vn",
  zh_CN = "zh-cn",
  zh_TW = "zh-tw",
}

export default RiotLocale;

export function isRiotLocale(str: RiotLocale | string | undefined): str is RiotLocale {
  return !!str && Object.values(RiotLocale).includes(str as RiotLocale);
}

export function riotLocaleFomString(locale: string): RiotLocale {
  if (!isRiotLocale(locale)) {
    throw new TypeError(`Invalid Locale: '${locale}'.`);
  }
  return locale;
}
