/*
  Minimal tween class for interpolating a normalized progress value over a duration.
  Designed to work directly with single-input Penner easing functions.
  ---
  duration            Number      Duration of the tween in milliseconds
  easing              String      Name of the easing function to apply
  onTickHandler       Function    Callback function to be fired each tick
  onCompleteHandler   Function    Callback function to be fired when the tween completes

  Notes:
  - Tick callback is passed both the `value` (eased) and `progress` (linear) values as arguments
  - Tween must be started by calling the `start()` function
  - Tween can be stopped early by calling the `kill()` function

  Example usage:

  const tween = new Tween(
    1000,
    'inOutCubic',
    ( value, progress ) => { console.log( value, progress ) },
    () => { console.log( 'complete!' ) }
  )
  tween.start()
*/

import { TickManagerInstance } from "../../../managers";
import { EasingFn, inOutSine } from "../../math/easing";

interface Config {
  duration: number;
  easing: EasingFn;
  onTickHandler: TickHandler;
  onCompleteHandler: CompleteHandler;
}

interface State {
  isRunning: boolean;
  startTime: number;
  progress: number;
  value: number;
}

type TickHandler = (value: number, progress: number) => void;
type CompleteHandler = () => void;

const DEFAULT_CONFIG: Config = {
  duration: 1000,
  easing: inOutSine,
  onTickHandler: (value: number, progress: number) => {
    console.log(`Tween update! Value: ${value}, progress: ${progress}`);
  },
  onCompleteHandler: () => {
    console.log("Tween complete!");
  },
};

const DEFAULT_STATE: State = {
  isRunning: false,
  startTime: 0,
  progress: 0,
  value: 0,
};

export default class {
  private _config: Config = DEFAULT_CONFIG;

  private _state: State = { ...DEFAULT_STATE };

  private _tickId: string | undefined;

  private _startTimer: any;

  constructor(duration: number, easing: EasingFn, onTickHandler: TickHandler, onCompleteHandler: CompleteHandler) {
    this._config = {
      ...this._config,
      duration,
      easing,
      onTickHandler,
      onCompleteHandler,
    };
  }

  start(delay = 0) {
    clearTimeout(this._startTimer);
    this._startTimer = setTimeout(() => this._start(), delay);
  }

  kill() {
    this._kill();
  }

  private _start() {
    this._state.isRunning = true;
    this._state.startTime = typeof window === "undefined" ? 0 : performance.now();

    this._tickId = TickManagerInstance.on(({ elapsed }) => this._tick(elapsed));
  }

  private _kill() {
    this._state.isRunning = false; // Will destroy() on next tick
  }

  private _complete() {
    this._config.onCompleteHandler();
    this._state.isRunning = false; // Will destroy() on next tick
  }

  private _destroy() {
    if (this._tickId) {
      TickManagerInstance.off(this._tickId);
      delete this._tickId;
    }
    clearTimeout(this._startTimer);
    this._config = DEFAULT_CONFIG;
    this._state = DEFAULT_STATE;
  }

  private _tick(elapsed: number) {
    // Check if tween has been killed
    if (!this._state.isRunning) {
      this._destroy();
      return;
    }

    // Continue with next tick
    this._state.progress = Math.min((elapsed - this._state.startTime) / this._config.duration, 1);
    this._state.value = this._config.easing(this._state.progress);

    this._config.onTickHandler(this._state.value, this._state.progress);

    // Check for completeness
    if (this._state.progress >= 1) {
      this._complete();
    }
  }
}
