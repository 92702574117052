/*
    Lerp (Linear Interpolate) between 2 values, clamping at small value changes
    ---
    a           Number    First value
    b           Number    Second value
    alpha       Number    Normalized 'alpha' value between 2 values (from 0-1)
    threshold   Number    Acceptable delta between values until clamping
    ---
    Returns     Number    Interpolated value clamped to threshold
*/

export default function (a: number, b: number, alpha = 0.1, threshold = 0.001) {
  const value = a + alpha * (b - a);

  return Math.abs(value - b) < threshold ? b : value;
}
