import React from "react";
import { GatsbyBrowser, GatsbySSR } from "gatsby";
import Layout from "../layouts/default";
import Helmet from "react-helmet";
import { ScrollContext } from "gatsby-react-router-scroll";

export interface PageContext extends Record<string, unknown> {
  language: string;
  url?: string;
  category_uid?: string;
  region?: RiotRegion;
  injectJavascriptHead?: string;
  googleOptimizeContainerID?: string;
}

import RiotLocaleContext from "../contexts/riot-locale";
import RiotRegion from "../enums/riot-region";

const wrapPageElement: wrapPageElementBrowser | wrapPageElementSSR = ({
  element,
  props: {
    pageContext: { language, region, injectJavascriptHead, googleOptimizeContainerID },
    custom404,
  },
}: Parameters<wrapPageElementBrowser>[0] | Parameters<wrapPageElementSSR>[0]): any => {
  let wrapper = (
    <RiotLocaleContext.Provider value={language}>
      <Helmet>
        <html lang={language} data-riot-locale={language} data-riot-region={region} />

        {/* Helmet doesn't like Fragments, so we do individuals for the optimize script and antiflicker */}
        {googleOptimizeContainerID ? <style type="text/css">{`.async-hide{opacity:0 !important;}`}</style> : null}
        {googleOptimizeContainerID ? (
          <script type="text/javascript" key="optimize-antiflicker">
            {`(function(a,s,y,n,c,h,i,d,e){s.className+=' '+y;h.start=1*new Date;
              h.end=i=function(){s.className=s.className.replace(RegExp(' ?'+y),'')};
              (a[n]=a[n]||[]).hide=h;setTimeout(function(){i();h.end=null},c);h.timeout=c;
              })(window,document.documentElement,'async-hide','dataLayer',4000,
              {'${googleOptimizeContainerID.replace(/[^a-zA-Z0-9 -]/gi, "")}':true});`}
          </script>
        ) : null}

        {googleOptimizeContainerID ? (
          <script
            async={true}
            key="optimize-include"
            src={`https://www.googleoptimize.com/optimize.js?id=${googleOptimizeContainerID.replace(
              /[^a-zA-Z0-9 -]/gi,
              "",
            )}`}
          ></script>
        ) : null}
        {googleOptimizeContainerID ? (
          <script type="text/javascript" key="optimize-antiflicker">
            {`window.dataLayer.push({'event': 'optimize.loaded'})`}
          </script>
        ) : null}

        {injectJavascriptHead && <script>{injectJavascriptHead}</script>}
      </Helmet>
      <Layout>{element}</Layout>
    </RiotLocaleContext.Provider>
  );

  if (custom404) {
    // WORKAROUND: For some reason gatsby doesn't wrap custom 404 error with <ScrollContext>
    // in @see .cache/root.js . So instead we do it ourselves here.
    wrapper = <ScrollContext location={location}>{wrapper}</ScrollContext>;
  }

  return wrapper;
};

export default wrapPageElement;

// types

interface WrapPageElementArgs {
  props: {
    pageContext: PageContext;
    custom404?: any;
  };
}

type wrapPageElementBrowser = (
  args: Parameters<NonNullable<GatsbyBrowser["wrapPageElement"]>>[0] & WrapPageElementArgs,
  options: Parameters<NonNullable<GatsbyBrowser["wrapPageElement"]>>[1],
) => any;

type wrapPageElementSSR = (
  args: Parameters<NonNullable<GatsbySSR["wrapPageElement"]>>[0] & WrapPageElementArgs,
  options: Parameters<NonNullable<GatsbySSR["wrapPageElement"]>>[1],
) => any;
